import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import React, { useState, useEffect, useMemo, Fragment } from 'react'
import styled, { css } from 'styled-components'

import type {
  ResourcePageQuery,
  ResourcePageQueryVariables,
} from '../../../graphql-types'
import Page from '../../components/layout/Page'
import { maxScreenWidth } from '../../components/mixins'
import Module, { Container } from '../../components/modules'
import Footer from '../../components/resource/Footer'
import Hero from '../../components/resource/Hero'
import { Color } from '../../components/theme'
import {
  captionRegularStyle,
  captionSemiboldStyle,
} from '../../components/typography'

export type Query = ResourcePageQuery
export type QueryVariables = ResourcePageQueryVariables
export type Context = QueryVariables

export const query = graphql`
  query ResourcePage($slug: String!) {
    page: contentfulResourcePage(
      slug: { eq: $slug }
      node_locale: { eq: "en" }
    ) {
      ...FullResourcePage
    }
  }
`

const HeroNav = styled.div`
  margin-top: 24px;
`

const FloatingNav = styled.div<{ isVisible: boolean }>`
  position: sticky;
  top: 145px;
  height: 0;
  padding-left: 2px;
  width: calc((100% - 720px) / 2 - 34px);
  z-index: 1;
  transition: opacity 200ms ease;

  ${maxScreenWidth(1100)(css`
    display: none;
  `)}

  ${({ isVisible }) =>
    !isVisible &&
    css`
      opacity: 0;
      visibility: hidden;
    `}
`

const Anchor = styled.a`
  position: relative;
  top: -${72 + 24}px;
`

const AnchorLink = styled.a<{ isActive?: boolean }>`
  display: block;

  ${({ isActive }) =>
    isActive
      ? css`
          ${captionSemiboldStyle}
          color: ${Color.PURPLE_6}!important;
        `
      : css`
          ${captionRegularStyle}

          ${FloatingNav} & {
            color: ${Color.GRAY_6};
          }

          &:hover {
            color: ${Color.BLACK};
          }

          span {
            text-decoration: underline;
          }
        `}

  ${HeroNav} & {
    margin-top: 8px;

    &:before {
      content: '•';
      margin-right: 8px;
    }
  }

  ${FloatingNav} & {
    font-size: 12px;
    margin-top: 12px;
  }
`

const onScrollHandler = (
  setCurrentAnchor: (anchor: string) => void,
  setVisibleFloat: (anchor: boolean) => void,
) => {
  let ticking = false

  return () => {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        const heroNavEls = document.getElementsByClassName(
          HeroNav.toString().substr(1),
        )
        const heroNavRect = heroNavEls[0]?.getBoundingClientRect()
        setVisibleFloat(!!heroNavRect && heroNavRect.bottom < 60)

        let current = {
          target: null,
        }
        const anchorEls = document.getElementsByClassName(
          Anchor.toString().substr(1),
        )
        Array.prototype.forEach.call(anchorEls, function (target) {
          const rect = target.getBoundingClientRect()
          if (!current.target || rect.top < 50) {
            current = {
              target,
            }
          }
        })

        if (current.target) {
          // @ts-ignore
          setCurrentAnchor(current.target.id)
        }

        ticking = false
      })
      ticking = true
    }
  }
}

const ResourcePage = ({ data: { page } }: PageProps<Query, Context>) => {
  const anchors = useMemo(
    () =>
      (page?.modules || []).reduce<Record<string, string>>(
        (anchors, module) => {
          if (
            module &&
            'anchor' in module &&
            'title' in module &&
            module.anchor &&
            module.title
          ) {
            anchors[module.anchor] = module.title
          }
          return anchors
        },
        {},
      ),
    [page?.modules],
  )

  const [currentAnchor, setCurrentAnchor] = useState<string | null>(
    Object.keys(anchors)[0] || null,
  )
  const [visibleFloat, setVisibleFloat] = useState(false)

  useEffect(() => {
    const onWindowScroll = onScrollHandler(setCurrentAnchor, setVisibleFloat)
    window.addEventListener('scroll', onWindowScroll)

    return () => {
      window.removeEventListener('scroll', onWindowScroll)
    }
  }, [setCurrentAnchor, setVisibleFloat])

  const anchorLinks = Object.entries(anchors).map(([anchor, title]) => (
    <AnchorLink
      key={anchor}
      href={`#${anchor}`}
      isActive={anchor === currentAnchor}
    >
      <span>{title}</span>
    </AnchorLink>
  ))

  return (
    <Page
      head={{
        title: `Customers - ${page?.title}`,
        description: page?.description?.description || page?.title || '',
        image: page?.poster?.file?.url,
        squareImage: page?.poster?.file?.url,
        openGraphType: 'article',
      }}
    >
      <Container>
        <Hero
          title={page?.title}
          image={page?.poster}
          category={page?.category}
        >
          {anchorLinks.length > 0 && <HeroNav>{anchorLinks}</HeroNav>}
        </Hero>
      </Container>

      <Container>
        {anchorLinks.length > 0 && (
          <FloatingNav isVisible={visibleFloat}>{anchorLinks}</FloatingNav>
        )}

        {page?.modules?.map(
          (module, index) =>
            !!module && (
              <Fragment key={`${module.__typename || ''}${index}`}>
                {anchors[module.anchor] && <Anchor id={module.anchor} />}
                <Module module={module} noContainer />
              </Fragment>
            ),
        )}
      </Container>

      <Footer />
    </Page>
  )
}

export default ResourcePage
